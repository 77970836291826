<template>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h3 v-if="title">{{ title }}</h3>
        <h4 v-if="subtitle">{{ subtitle }}</h4>
        <div class="row">
          <ul class="col-md-4 list-unstyled">
            <li v-for="(s, idx) in take(data, 0, 3)" :key="`interest-0-${idx}`">
              {{ s }}
            </li>
          </ul>

          <ul class="col-md-4 list-unstyled">
            <li v-for="(s, idx) in take(data, 1, 3)" :key="`interest-1-${idx}`">
              {{ s }}
            </li>
          </ul>

          <ul class="col-md-4 list-unstyled">
            <li v-for="(s, idx) in take(data, 2, 3)" :key="`interest-2-${idx}`">
              {{ s }}
            </li>
          </ul>
        </div>

        <span class="text-muted small">Source: {{ source }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InterestsCard",
  props: {
    interestMap: Object,
    title: String,
    subtitle: String,
    source: String,
    limit: Number,
  },
  computed: {
    data() {
      let keys = Object.keys(this.interestMap).sort((a, b) => {
        return this.interestMap[b] - this.interestMap[a];
      });

      if (this.limit && this.limit < keys.length) {
        return keys.slice(0, this.limit).sort();
      } else {
        return keys.sort();
      }
    },
  },
  methods: {
    take(list, number, fraction) {
      let slices = fraction;
      let perSlice = Math.ceil(list.length / slices);
      return list.slice(number * perSlice, number * perSlice + perSlice);
    },
  },
};
</script>
