<template>
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <h3>Employee size of organizations that are searching here:</h3>
        <apexchart
          type="bar"
          height="280"
          :options="chartOptions"
          :series="series"
        ></apexchart>

        <span class="text-muted small">Source: Bombora</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SizeCard",
  props: {
    sizeMap: Object,
  },
  computed: {
    series() {
      let data = this.sizeMap;

      let sizes = [
        data["XXLarge (10,000+ Employees)"] || 0,
        data["XLarge (5,000 - 10,000 Employees)"] || 0,
        data["Large (1,000 - 4,999 Employees)"] || 0,
        data["Medium-Large (500 - 999 Employees)"] || 0,
        data["Medium (200 - 499 Employees)"] || 0,
        data["Medium-Small (50 - 199 Employees)"] || 0,
        data["Small (10 - 49 Employees)"] || 0,
        data["Micro (1 - 9 Employees)"] || 0,
      ];

      return [
        {
          name: "Companies",
          data: sizes,
        },
      ];
    },
    chartOptions() {
      return {
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              totals: {
                enabled: false,
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "10,000+",
            "5,000 - 10,000",
            "1,000 - 4,999",
            "500 - 999",
            "200 - 499",
            "50 - 199",
            "10 - 49",
            "1 - 9",
          ],
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            showAlways: false,
          },
        },
      };
    },
  },
  data: () => {
    return {};
  },
};
</script>
