<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col-8">
            <h2 class="page-title">Audience Insights</h2>
          </div>
          <div class="col-4">
            <select v-model="selectedSite" class="form-select">
              <option
                v-for="site in siteNames"
                v-bind:key="site"
                :value="sites[site]"
              >
                {{ site }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div class="row row-cards mb-3">
          <div class="col-lg-3">
            <div class="card h-100">
              <div class="card-body text-center">
                <a :href="`https://${site.directoryurl}/`" target="_blank">
                  <img
                    :src="`https://cdn.mediabrains.com/directorylogos/${site.projectname}_pubhome.gif`"
                  />
                </a>

                <p class="subheader">{{ site.sitename }}</p>
              </div>
            </div>
          </div>

          <div class="col-lg-9">
            <div class="card h-100">
              <div class="card-body">
                <h3>Real time insights into your target customers.</h3>
                <p>
                  The audience insights report highlights key information about
                  the buyers visiting this specific directory. Uncover audience
                  opportunities by understanding the demographics of who is
                  here, where they work, and identifying the solutions they're
                  searching for.
                </p>
                <div class="d-flex align-items-center">
                  <strong class="me-2">Reach this audience:</strong>
                  <a
                    :href="`https://${site.directoryurl}/addyourcompany?utm_source=audience_report`"
                    target="_blank"
                    class="btn btn-primary me-2"
                    v-if="showAYC"
                    @click="trackAYCClick"
                  >
                    Add your company to this directory
                  </a>
                  <router-link
                    :to="`/newupgrade?site=${selectedSite}&showTop=1`"
                    class="btn btn-outline-primary"
                    v-if="$auth && $auth.activeCompany"
                  >
                    Upgrade your listing
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row row-cards mb-3"
          v-if="
            audienceData.search_impressions > 0 &&
            Object.keys(audienceData.site_search_console).length > 0
          "
        >
          <interests-card
            :interestMap="audienceData.site_search_console"
            :title="`${
              this.site.sitename
            } appeared in ${audienceData.search_impressions.toLocaleString()} Google search results this month.`"
            :subtitle="`Keywords people are searching:`"
            :source="`Google Search Console`"
            :limit="30"
          ></interests-card>
        </div>

        <div class="row row-cards mb-3">
          <companies-card
            :interestMap="audienceData.domain"
            :title="`A sample of companies conducting research using this directory:`"
            :limit="9"
          ></companies-card>

          <div class="col-md-3">
            <div class="card h-100">
              <div class="card-body h-100 d-flex flex-column">
                <h3 class="mb-0 pb-0">
                  Impressions delivered to companies listed in this directory in
                  the last month:
                </h3>
                <div
                  class="d-flex flex-column align-items-center justify-content-center h-100"
                >
                  <h1 style="font-size: 48px" class="mb-0 pb-0">
                    {{
                      (
                        audienceData.impressions + audienceData.visits
                      ).toLocaleString()
                    }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cards">
          <interests-card
            :interestMap="audienceData.visitor_intents"
            :title="`Buyer intent data indicates the market interests of prospective customers based on which products and services they are interested in.`"
            :subtitle="`What prospects using this directory want to buy:`"
            :source="`Bombora`"
            :limit="30"
          ></interests-card>

          <seniority-card
            :seniorityMap="audienceData.seniority"
          ></seniority-card>
          <size-card :sizeMap="audienceData.company_size"></size-card>

          <industry-card :industryMap="audienceData.industry"></industry-card>

          <router-link
            :to="`/admin/report/audience?site=${site.siteid}`"
            v-if="$auth.superuser"
            >Report Admin View</router-link
          >
        </div>
      </div>
      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Preparing audience report...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteService from '../../services/SiteService'
import APIService from '../../services/APIService'
import NotificationsService from '../../services/NotificationsService'
import TrackingService from '../../services/TrackingService'

import InterestsCard from '../../components/Audience/InterestsCard.vue'
import IndustryCard from '../../components/Audience/IndustryCard.vue'
import SeniorityCard from '../../components/Audience/SeniorityCard.vue'
import SizeCard from '../../components/Audience/SizeCard.vue'
import CompaniesCard from '../../components/Audience/CompaniesCard.vue'

export default {
  name: "AudienceReport",
  components: {
    InterestsCard,
    IndustryCard,
    SeniorityCard,
    SizeCard,
    CompaniesCard,
  },
  data: () => {
    return {
      base_url: APIService.mb_base_url,
      loaded: false,
      sites: {},
      site: null,
      siteSeo: null,
      selectedSite: null,
      audienceData: null,
      report: null,
    };
  },
  methods: {
    trackAYCClick() {
      TrackingService.track("clicked add your company link", {
        siteId: this.selectedSite,
      });
    },
  },
  computed: {
    siteNames() {
      return Object.keys(this.sites).sort();
    },
    traffic() {
      if (this.site.traffic) {
        let amount = parseInt(
          this.site.traffic.split("-")[1].trim()
        ).toLocaleString();
        return `up to ${amount}`;
      } else {
        return "";
      }
    },
    showAYC() {
      if (this.$auth && this.$auth.activeCompany) {
        return (
          this.$auth.activeCompany.sites.filter(
            (s) => s.siteID === this.selectedSite
          ).length === 0
        );
      } else {
        return true;
      }
    },
  },
  watch: {
    async selectedSite() {
      if (this.$route.query && this.$route.query.site !== this.selectedSite) {
        await this.$router.replace({
          path: "/reports/audience-report",
          query: { site: this.selectedSite },
        });
      } else if (!this.$route.query) {
        await this.$router.replace({
          path: "/reports/audience-report",
          query: { site: this.selectedSite },
        });
      }
      this.loaded = false;
      let site = await SiteService.getSite(this.selectedSite);
      this.siteSeo = await SiteService.getSiteSeo(this.selectedSite);
      this.site = site;
      let notifications = await NotificationsService.getAudienceReport(
        this.site.siteid
      );
      TrackingService.track("view audience report", {
        siteId: this.site.siteid,
      });
      this.report = notifications;
      this.audienceData = notifications.siteStats;
      this.loaded = true;
    },
  },
  async mounted() {
    await this.$auth.identify();
    this.sites = await SiteService.getAllSites();
    if (this.$route.query.site) {
      this.selectedSite = this.$route.query.site;
    } else if (
      this.$auth &&
      this.$auth.activeCompany &&
      this.$auth.activeCompany.sites &&
      this.$auth.activeCompany.sites.length > 0
    ) {
      this.selectedSite = this.$auth.activeCompany.sites.sort((a, b) => {
        return a.siteRank - b.siteRank;
      })[0].siteID;
    } else {
      this.selectedSite = Object.values(this.sites)[0];
    }
  },
};
</script>
