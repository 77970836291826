import APIService from "./APIService";
import { parseJSON } from "date-fns";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getNotifications(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Notifications/company",
      company,
      authHeaders
    );
    let notifications = res.data.map((n) => {
      n["postedDate"] = parseJSON(n["postedDate"]);
      return n;
    });
    return notifications;
  },
  async getVisitorInsights(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Notifications/visitor-insights",
      company,
      authHeaders
    );
    return res.data;
  },
  async getVisitorInsightsCsv(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Notifications/visitor-insights-csv",
      company,
      authHeaders
    );
    return res.data;
  },
  async getAudienceReport(site) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(`api/notifications/site/${site}`, authHeaders);
    return res.data;
  },
  async getAllAudienceReports(site) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(`api/notifications/site/${site}/all`, authHeaders);
    return res.data;
  },
  async getSpecificAudienceReport(reportName) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/notifications/report/specific?report=${encodeURIComponent(
        reportName
      )}`,
      authHeaders
    );
    return res.data;
  },
  async finalizeAudienceReport(site, reportName, builtReport) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      `api/notifications/site/${site}/finalize?reportName=${encodeURIComponent(
        reportName
      )}`,
      builtReport,
      authHeaders
    );
    return res.data;
  },
};
