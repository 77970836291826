<template>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <h3>Buyers work for companies in these industries:</h3>
        <apexchart
          type="pie"
          height="240"
          :options="chartOptions"
          :series="series"
        ></apexchart>
        <span class="text-muted small">Source: Bombora</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndustryCard",
  props: {
    industryMap: Object,
  },
  computed: {
    labels() {
      let things = Object.keys(this.industryMap)
        .sort((a, b) => this.industryMap[b] - this.industryMap[a])
        .slice(0, this.showTop)
        .map((x) => (x.includes("|") ? x.split("|")[1] : x));
      things.push("Other Industries");
      return things;
    },
    series() {
      let things = Object.keys(this.industryMap)
        .sort((a, b) => this.industryMap[b] - this.industryMap[a])
        .slice(0, this.showTop)
        .map((i) => this.industryMap[i]);
      things.push((things.reduce((a, b) => a + b) / 3) * 1.25);
      return things;
    },
    chartOptions() {
      return {
        labels: this.labels,
        tooltip: {
          enabled: false,
        },
        theme: {
          palette: "palette10", // upto palette10
        },
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "treemap",
        },
        yaxis: {
          show: false,
        },
      };
    },
  },
  data: () => {
    return {
      showTop: 4,
    };
  },
};
</script>
