<template>
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <h3>Seniority level of professionals using this directory:</h3>
        <apexchart
          type="bar"
          height="280"
          :options="chartOptions"
          :series="series"
        ></apexchart>

        <span class="text-muted small">Source: Bombora</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeniorityCard",
  props: {
    seniorityMap: Object,
  },
  computed: {
    series() {
      let data = this.seniorityMap;

      let actualData = [
        (data["Board and Ownership"] || 0) +
          (data["Csuite|Board and Ownership"] || 0) +
          (data["Management|Board and Ownership"] || 0),
        (data["Csuite"] || 0) +
          (data["Management|Csuite"] || 0) +
          (data["Csuite|Board and Ownership"] || 0),
        (data["Management"] || 0) +
          (data["Management|Csuite"] || 0) +
          (data["Management|Board and Ownership"] || 0),
        data["Non-management"] || 0,
      ];

      return [
        {
          name: "Professionals",
          data: actualData,
        },
      ];
    },
    chartOptions() {
      return {
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              totals: {
                enabled: false,
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "Board and Ownership",
            "Csuite",
            "Management",
            "Non-management",
          ],
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            showAlways: false,
          },
        },
      };
    },
  },
  data: () => {
    return {};
  },
};
</script>
