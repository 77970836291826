<template>
  <div class="col-lg-9" v-if="data.length > 0">
    <div class="card">
      <div class="card-body">
        <h3>A sample of companies conducting research using this directory:</h3>

        <div class="row">
          <ul class="col-md-4 list-unstyled">
            <li
              v-for="(s, idx) in take(data, 0, 3)"
              :key="`company-${idx}`"
              class="d-flex align-items-center mb-2"
            >
              <img
                :src="`https://logo.clearbit.com/${s}`"
                style="width: 36px; height: 36px"
                @error="excludeDomains.push(s)"
                class="me-2"
              />
              <span class="me-1">{{ s }}</span>
              <a :href="`https://${s}`" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-external-link"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                  ></path>
                  <path d="M10 14l10 -10"></path>
                  <path d="M15 4l5 0l0 5"></path>
                </svg>
              </a>
            </li>
          </ul>

          <ul class="col-md-4 list-unstyled">
            <li
              v-for="(s, idx) in take(data, 1, 3)"
              :key="`company-${idx}`"
              class="d-flex align-items-center mb-2"
            >
              <img
                :src="`https://logo.clearbit.com/${s}`"
                style="width: 36px; height: 36px"
                @error="excludeDomains.push(s)"
                class="me-2"
              />
              <span class="me-1">{{ s }}</span>
              <a :href="`https://${s}`" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-external-link"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                  ></path>
                  <path d="M10 14l10 -10"></path>
                  <path d="M15 4l5 0l0 5"></path>
                </svg>
              </a>
            </li>
          </ul>

          <ul class="col-md-4 list-unstyled">
            <li
              v-for="(s, idx) in take(data, 2, 3)"
              :key="`company-${idx}`"
              class="d-flex align-items-center mb-2"
            >
              <img
                :src="`https://logo.clearbit.com/${s}`"
                style="width: 36px; height: 36px"
                @error="excludeDomains.push(s)"
                class="me-2"
              />
              <span class="me-1">{{ s }}</span>
              <a :href="`https://${s}`" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-external-link"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                  ></path>
                  <path d="M10 14l10 -10"></path>
                  <path d="M15 4l5 0l0 5"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>

        <span class="text-muted small">
          Source: Bombora,
          <a
            href="https://clearbit.com"
            class="link-style-none text-muted"
            target="_blank"
          >
            logos provided by Clearbit
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompaniesCard",
  props: {
    interestMap: Object,
    title: String,
    limit: Number,
  },
  data() {
    return {
      excludeDomains: [],
    };
  },
  computed: {
    data() {
      let keys = Object.keys(this.interestMap).sort((a, b) => {
        return this.interestMap[b] - this.interestMap[a];
      });

      keys = keys.filter((x) => !this.excludeDomains.includes(x));

      if (this.limit && this.limit < keys.length) {
        return keys.slice(0, this.limit).sort();
      } else {
        return keys.sort();
      }
    },
  },
  methods: {
    take(list, number, fraction) {
      let slices = fraction;
      let perSlice = Math.ceil(list.length / slices);
      return list.slice(number * perSlice, number * perSlice + perSlice);
    },
  },
};
</script>
